'use client'

import { useEffect, useState, useRef, memo } from 'react'
import { 
  MessageSquare, 
  ChevronLeft, 
  ChevronRight 
} from 'lucide-react'
import VideoPlayer from './components/VideoPlayer' // Adjust the path based on your project structure

// Custom SVG Components
const MovieIcon = () => (
  <svg className="w-10 h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* SVG Paths */}
    <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M21.5 8H2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M21.5 16H2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M6.5 8V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M17.5 8V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M6.5 2V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M17.5 2V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

const TvShowIcon = () => (
  <svg className="w-10 h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* SVG Paths */}
    <rect x="2" y="5" width="20" height="15" rx="2" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M12 20V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M8 2L12 5L16 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="18" cy="9" r="1" fill="currentColor"/>
    <path d="M6.5 8H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M8 6V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

const VideoGameIcon = () => (
  <svg className="w-10 h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* SVG Paths */}
    <path d="M6 11H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M8 9V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M17 10H17.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M15 12.01L15.01 11.9989" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M2 9.4V14.6C2 16.8402 2 17.9603 2.43597 18.816C2.81947 19.5686 3.43139 20.1805 4.18404 20.564C5.03968 21 6.15979 21 8.4 21H15.6C17.8402 21 18.9603 21 19.816 20.564C20.5686 20.1805 21.1805 19.5686 21.564 18.816C22 17.9603 22 16.8402 22 14.6V9.4C22 7.15979 22 6.03969 21.564 5.18404C21.1805 4.43139 20.5686 3.81947 19.816 3.43597C18.9603 3 17.8402 3 15.6 3H8.4C6.15979 3 5.03969 3 4.18404 3.43597C3.43139 3.81947 2.81947 4.43139 2.43597 5.18404C2 6.03969 2 7.15979 2 9.4Z" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
)

const ExperimentalIcon = () => (
  <svg className="w-10 h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* SVG Paths */}
    <path d="M10 2.68934V8C10 8.66667 9.5 10 7.5 10C5.5 10 5 8.66667 5 8V2.68934C5 2.30864 5.30864 2 5.68934 2C6.28199 2 6.81658 2.3342 7.12303 2.86461L7.5 3.5L7.87697 2.86461C8.18342 2.3342 8.71801 2 9.31066 2C9.69136 2 10 2.30864 10 2.68934Z" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M19 2.68934V8C19 8.66667 18.5 10 16.5 10C14.5 10 14 8.66667 14 8V2.68934C14 2.30864 14.3086 2 14.6893 2C15.282 2 15.8166 2.3342 16.123 2.86461L16.5 3.5L16.877 2.86461C17.1834 2.3342 17.718 2 18.3107 2C18.6914 2 19 2.30864 19 2.68934Z" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M9 16L7.5 19L12 22L16.5 19L15 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 22V13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M5 10H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

// Memoized Channel Button Component
const ChannelButton = memo(({ channel, handleHover, videoRefs, isVideoPlaying, handleVideoEnd, onClick }) => (
  <button
    key={channel.id}
    className={`relative h-32 rounded-2xl bg-gradient-to-br ${channel.gradient} 
      border border-neutral-800 transition-all duration-200 
      hover:border-yellow-500 hover:scale-105 focus:outline-none
      focus:border-yellow-500 group overflow-hidden`}
    onMouseEnter={() => handleHover(channel.id, true)}
    onMouseLeave={() => handleHover(channel.id, false)}
    onClick={channel.video ? onClick : undefined} // Only add onClick if channel has video
  >
    {channel.icon ? (
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-white group-hover:text-yellow-500 transition-colors">
          {channel.icon}
        </div>
      </div>
    ) : (
      <>
        <img 
          src={channel.image} 
          alt={channel.name} 
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${isVideoPlaying ? 'opacity-0' : 'opacity-100'}`}
          loading="lazy" // Lazy loading for images
        />
        {channel.video && (
          <video 
            ref={el => {
              if (el) {
                videoRefs.current[channel.id] = el
              }
            }}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${isVideoPlaying ? 'opacity-100' : 'opacity-0'}`}
            muted
            loop={false} // Not looping
            playsInline
            onError={(e) => console.error(`Error loading video for channel ${channel.name}:`, e)}
            onEnded={() => handleVideoEnd(channel.id)}
          >
            <source src={channel.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </>
    )}
    <div className="absolute bottom-4 left-4 z-10">
      <span className="text-lg text-white group-hover:text-yellow-500 transition-colors">
        {channel.name}
      </span>
    </div>
  </button>
))

export default function ArtPage() {
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [currentPage, setCurrentPage] = useState(0)
  const videoRefs = useRef({})
  const cursorRef = useRef(null)
  const hoverTimeouts = useRef({})
  
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState({
    videoSrc: '',
    title: '',
    description: ''
  })

  // Update the current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  // Custom cursor movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cursorRef.current) {
        cursorRef.current.style.left = `${e.clientX}px`
        cursorRef.current.style.top = `${e.clientY}px`
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  // Prevent background scrolling when video player is open
  useEffect(() => {
    if (isVideoPlayerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isVideoPlayerOpen])

  const channels = [
    { id: 1, name: 'Movies', icon: <MovieIcon />, gradient: 'from-neutral-900 to-neutral-800' },
    { id: 2, name: 'Series', icon: <TvShowIcon />, gradient: 'from-neutral-900 to-neutral-800' },
    { id: 3, name: 'Games', icon: <VideoGameIcon />, gradient: 'from-neutral-900 to-neutral-800' },
    { id: 4, name: 'Experiments', icon: <ExperimentalIcon />, gradient: 'from-neutral-900 to-neutral-800' },
    { id: 5, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 6, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 7, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 8, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 9, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 10, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 11, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 12, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 13, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 14, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 15, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 16, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 17, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 18, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 19, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 20, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 21, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 22, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 23, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
    { id: 24, name: 'Coming Soon', image: '/assets/images/portal.png', video: 'assets/video/beautiful_portal.mov', title: 'Coming Soon', description: 'Coming Soon' },
  ];

  const handleHover = (channelId, isEntering) => {
    if (isEntering) {
      // Start a 1.5-second timer before playing the video
      hoverTimeouts.current[channelId] = setTimeout(() => {
        setSelectedChannel(channelId)
        const video = videoRefs.current[channelId]
        if (video) {
          video.currentTime = 0
          video.play().catch(error => {
            console.error(`Error playing video for channel ${channelId}:`, error)
          })
        }
      }, 1500) // 1500 milliseconds = 1.5 seconds
    } else {
      // Clear the timer if the user moves the mouse away before 1.5 seconds
      if (hoverTimeouts.current[channelId]) {
        clearTimeout(hoverTimeouts.current[channelId])
        delete hoverTimeouts.current[channelId]
      }
      // Pause the video if it's playing
      setSelectedChannel(null)
      const video = videoRefs.current[channelId]
      if (video) {
        video.pause()
      }
    }
  }

  const handleVideoEnd = (channelId) => {
    if (selectedChannel === channelId) {
      setSelectedChannel(null)
    }
  }

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
  }

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
    })
  }

  const renderArrow = (direction) => (
    <button
      onClick={() => setCurrentPage(direction === 'left' ? 0 : 1)}
      className={`absolute ${direction === 'left' ? '-left-16' : '-right-16'} top-1/2 -translate-y-1/2
        w-12 h-24 bg-neutral-700 bg-opacity-70 hover:bg-opacity-90 text-white
        flex items-center justify-center transition-all duration-200
        rounded-lg hover:scale-110 focus:outline-none focus:ring-2 focus:ring-yellow-500
        backdrop-blur-sm shadow-lg`}
      disabled={currentPage === (direction === 'left' ? 0 : 1)}
      aria-label={`Go to ${direction === 'left' ? 'previous' : 'next'} page`}
    >
      {direction === 'left' ? (
        <ChevronLeft className="w-8 h-8" />
      ) : (
        <ChevronRight className="w-8 h-8" />
      )}
    </button>
  )

  // Function to open the VideoPlayer popup
  const openVideoPlayer = (channel) => {
    setSelectedVideo({
      videoSrc: channel.video,
      title: channel.title || channel.name,
      description: channel.description || ''
    })
    setIsVideoPlayerOpen(true)
  }

  // Function to close the VideoPlayer popup
  const closeVideoPlayer = () => {
    setIsVideoPlayerOpen(false)
    // Optionally pause the video if still playing
    const video = videoRefs.current[selectedChannel]
    if (video) {
      video.pause()
      setSelectedChannel(null)
    }
  }

  return (
    <div 
      className="bg-black p-8 relative overflow-hidden font-mono"
    >
      <div className="max-w-5xl mx-auto">
        <div className="relative">
          <div className="grid grid-cols-4 gap-4 mb-8">
            {currentPage === 0 ? (
              <>
                {channels.slice(0, 12).map(channel => (
                  <ChannelButton 
                    key={channel.id} 
                    channel={channel} 
                    handleHover={handleHover} 
                    videoRefs={videoRefs}
                    isVideoPlaying={selectedChannel === channel.id}
                    handleVideoEnd={handleVideoEnd}
                    onClick={() => openVideoPlayer(channel)}
                  />
                ))}
              </>
            ) : (
              <>
                {channels.slice(12).map(channel => (
                  <ChannelButton 
                    key={channel.id} 
                    channel={channel} 
                    handleHover={handleHover} 
                    videoRefs={videoRefs}
                    isVideoPlaying={selectedChannel === channel.id}
                    handleVideoEnd={handleVideoEnd}
                    onClick={() => openVideoPlayer(channel)}
                  />
                ))}
              </>
            )}
          </div>
          {renderArrow('left')}
          {renderArrow('right')}
        </div>

        {/* Bottom Section */}
        <div className="h-24 relative">
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black to-transparent" />
          <div className="relative h-full max-w-5xl mx-auto px-8">
            <div className="absolute bottom-0 left-8 flex items-center gap-4">
              <button 
                className="w-12 h-12 rounded-full bg-neutral-900 border border-neutral-800 hover:border-yellow-500 flex items-center justify-center transition-colors"
                aria-label="PC Button"
              >
                <span className="text-xl font-bold text-white">[pc]</span>
              </button>
            </div>
            
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-center">
              <div className="text-2xl font-bold text-white tabular-nums">
                {formatTime(currentTime)}
              </div>
              <div className="text-neutral-400">
                {formatDate(currentTime)}
              </div>
            </div>

            <div className="absolute bottom-4 right-8">
              <button 
                className="w-12 h-12 rounded-full bg-neutral-900 border border-neutral-800 hover:border-yellow-500 flex items-center justify-center transition-colors"
                aria-label="Message Button"
              >
                <MessageSquare className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Cursor */}
      <div
        ref={cursorRef}
        className="pointer-events-none fixed w-8 h-8 transition-all duration-75 ease-out"
        style={{
          transform: 'translate(-50%, -50%)',
          background: 'radial-gradient(circle, rgba(234,179,8,0.3) 0%, rgba(234,179,8,0) 70%)'
        }}
      />

      {/* Video Player Popup */}
      {isVideoPlayerOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 transition-opacity duration-300"
          onClick={closeVideoPlayer}
        >
          <div 
            className="relative w-4/5 h-4/5"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the player
          >
            <VideoPlayer 
              videoSrc={selectedVideo.videoSrc}
              title={selectedVideo.title}
              description={selectedVideo.description}
              onClose={closeVideoPlayer}
            />
          </div>
        </div>
      )}
    </div>
  )
}