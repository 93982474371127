'use client'

import { useState, useRef, useEffect } from 'react'
import { Play, Pause, Volume2, VolumeX, Maximize, Minimize, SkipBack, SkipForward } from 'lucide-react'

export default function VideoPlayer() {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [showControls, setShowControls] = useState(true)
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const controlsTimeoutRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const updateProgress = () => {
      if (video.duration) {
        setProgress((video.currentTime / video.duration) * 100)
      }
    }

    const handleLoadedMetadata = () => {
      setDuration(video.duration)
    }

    video.addEventListener('timeupdate', updateProgress)
    video.addEventListener('loadedmetadata', handleLoadedMetadata)

    return () => {
      video.removeEventListener('timeupdate', updateProgress)
      video.removeEventListener('loadedmetadata', handleLoadedMetadata)
    }
  }, [])

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFull = !!document.fullscreenElement
      setIsFullscreen(isFull)
      if (!isFull) {
        setShowControls(false) // Optionally hide controls when exiting fullscreen
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  const togglePlay = () => {
    const video = videoRef.current
    if (video) {
      if (isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const toggleMute = () => {
    const video = videoRef.current
    if (video) {
      video.muted = !isMuted
      setIsMuted(!isMuted)
    }
  }

  const toggleFullscreen = () => {
    const player = playerRef.current
    if (!document.fullscreenElement) {
      player?.requestFullscreen()
      // setIsFullscreen(true) // No longer needed; handled by fullscreenchange event
    } else {
      document.exitFullscreen()
      // setIsFullscreen(false) // No longer needed; handled by fullscreenchange event
    }
  }

  const seek = (e) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const percent = (e.clientX - rect.left) / rect.width
    const video = videoRef.current
    if (video) {
      video.currentTime = percent * video.duration
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const skipBackward = () => {
    const video = videoRef.current
    if (video) {
      video.currentTime = Math.max(video.currentTime - 10, 0)
    }
  }

  const skipForward = () => {
    const video = videoRef.current
    if (video) {
      video.currentTime = Math.min(video.currentTime + 10, video.duration)
    }
  }

  const handleMouseMove = () => {
    if (isFullscreen) { // Show controls if in fullscreen
      setShowControls(true)
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current)
      }
      controlsTimeoutRef.current = setTimeout(() => {
        setShowControls(false)
      }, 3000)
    } else { // Optionally handle non-fullscreen behavior
      setShowControls(true)
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current)
      }
      controlsTimeoutRef.current = setTimeout(() => {
        setShowControls(false)
      }, 3000)
    }
  }

  return (
    <div 
      ref={playerRef}
      className="relative w-full max-w-4xl mx-auto aspect-video bg-black rounded-lg overflow-hidden shadow-2xl"
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setShowControls(false)}
    >
      <video
        ref={videoRef}
        className="w-full h-full object-cover cursor-pointer"
        src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        onClick={togglePlay}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      {showControls && (
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70" />
      )}
      <div className={`absolute bottom-0 left-0 right-0 p-4 transition-opacity duration-300 ${showControls ? 'opacity-100' : 'opacity-0'}`}>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center space-x-2">
            <button onClick={togglePlay} className="text-white hover:text-yellow-500 transition-colors">
              {isPlaying ? <Pause className="w-8 h-8" /> : <Play className="w-8 h-8" />}
            </button>
            <button onClick={skipBackward} className="text-white hover:text-yellow-500 transition-colors">
              <SkipBack className="w-6 h-6" />
            </button>
            <button onClick={skipForward} className="text-white hover:text-yellow-500 transition-colors">
              <SkipForward className="w-6 h-6" />
            </button>
            <button onClick={toggleMute} className="text-white hover:text-yellow-500 transition-colors">
              {isMuted ? <VolumeX className="w-6 h-6" /> : <Volume2 className="w-6 h-6" />}
            </button>
          </div>
          <button onClick={toggleFullscreen} className="text-white hover:text-yellow-500 transition-colors">
            {isFullscreen ? <Minimize className="w-6 h-6" /> : <Maximize className="w-6 h-6" />}
          </button>
        </div>
        <div className="relative h-1 bg-neutral-700 rounded-full overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-yellow-500 transition-all duration-100"
            style={{ width: `${progress}%` }}
          />
          <div
            className="absolute top-0 left-0 w-full h-full cursor-pointer"
            onClick={seek}
          />
        </div>
        <div className="flex justify-between text-sm text-white mt-1">
          <span>{formatTime(videoRef.current?.currentTime || 0)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      {!isFullscreen && (
        <div className="absolute top-4 left-4 right-4">
          <h2 className="text-2xl font-bold text-white">Cyberpunk: Neon Nights</h2>
          <p className="text-sm text-neutral-400">A journey through the digital underworld</p>
        </div>
      )}
    </div>
  )
}