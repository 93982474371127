import React from 'react';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import BlogPost from './BlogPost';
import blogPostsData from '../data/blogPost.json';
import '../ModalStyles.css'; // Import the custom CSS

Modal.setAppElement('#root'); // Accessibility requirement

const ModalBlogPost = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const post = blogPostsData.find(p => p.id === id);

  const closeModal = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (!post) {
    return <div className="text-center py-12 text-red-500">Post not found.</div>;
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel={post.title}
      overlayClassName="modal-overlay" // Apply the overlay class
      className="modal-content" // Apply the content class
    >
      <button 
        onClick={closeModal} 
        className="absolute top-4 right-4 text-yellow-400 hover:text-yellow-300 text-2xl"
        aria-label="Close"
      >
        &times;
      </button>
      <BlogPost {...post} />
    </Modal>
  );
};

export default ModalBlogPost;




