
import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon
} from 'react-share';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';

const BlogPost = ({ title, date, author, tags, imageUrl, content }) => {
  const shareUrl = window.location.href; // Current page URL

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        toast.success('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy the link.');
      });
  };

  return (
    <div>
      <h1 className="text-4xl font-bold mb-6 text-yellow-400">{title}</h1>
      
      {imageUrl && (
        <img 
          src={imageUrl} 
          alt={title}
          className="w-full h-auto mb-6 rounded-lg"
        />
      )}

      <div className="flex flex-wrap gap-4 mb-6 text-gray-400 text-sm">
        <span>Date: {date}</span>
        <span>Author: {author}</span>
        <div>
          Tags: 
          {tags.map((tag, index) => (
            <span key={index} className="bg-yellow-400 text-black px-2 py-1 rounded ml-2 text-xs">
              {tag}
            </span>
          ))}
        </div>
      </div>

      {/* Share Buttons */}
      <div className="flex items-center gap-4 mb-6">
        <span className="text-gray-400">Share:</span>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <button 
          onClick={copyToClipboard}
          className="flex items-center justify-center w-10 h-10 bg-gray-800 text-yellow-400 rounded-full hover:bg-gray-700 transition-colors"
          title="Copy Link"
        >
          <FiCopy size={20} />
        </button>
      </div>

      <div className="prose prose-invert">
        {content.split('\n').map((paragraph, index) => (
          <p key={index} className="mb-4">{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

// Styles Object
const styles = {
  container: {
    minHeight: '100vh',
    backgroundColor: '#000',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    padding: '20px'
  },
  contentWrapper: {
    maxWidth: '800px',
    margin: '0 auto'
  },
  title: {
    fontSize: '2.5rem',
    color: '#00b0ff',
    marginBottom: '20px'
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: '20px'
  },
  metaData: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '20px',
    fontSize: '0.9rem',
    color: '#888'
  },
  tag: {
    backgroundColor: '#00b0ff',
    color: '#000',
    padding: '2px 6px',
    borderRadius: '4px',
    marginLeft: '5px'
  },
  content: {
    lineHeight: '1.6',
    fontSize: '1.1rem'
  },
  paragraph: {
    marginBottom: '15px'
  }
};

export default BlogPost;