'use client';

import { useState, useEffect } from 'react';
import { Search, Tag, ChevronDown, Zap } from 'lucide-react';

const StarryBackground = () => {
  useEffect(() => {
    const createStar = () => {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.left = `${Math.random() * 100}vw`;
      star.style.top = `${Math.random() * 100}vh`;
      star.style.animationDuration = `${Math.random() * 3 + 2}s`;
      star.style.width = star.style.height = `${Math.random() * 2 + 1}px`;
      document.getElementById('starry-background')?.appendChild(star);
    };

    for (let i = 0; i < 200; i++) {
      createStar();
    }

    return () => {
      const bg = document.getElementById('starry-background');
      if (bg) bg.innerHTML = '';
    };
  }, []);

  return <div id="starry-background" className="fixed inset-0 z-0 pointer-events-none" />
};

export default function BlogPage() {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedTag, setSelectedTag] = useState('All');

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch('/api/posts');
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
    }
  };

  const categories = ['All', ...new Set(posts.map((post) => post.category))];
  const tags = ['All', ...new Set(posts.flatMap((post) => post.tags))];

  const filteredPosts = posts.filter(
    (post) =>
      (selectedCategory === 'All' || post.category === selectedCategory) &&
      (selectedTag === 'All' || post.tags.includes(selectedTag)) &&
      (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="min-h-screen bg-black text-white font-mono relative overflow-hidden">
      <StarryBackground />
      <div className="max-w-4xl mx-auto px-4 py-8 relative z-10">
        <h1 className="text-4xl font-bold text-center mb-8 text-yellow-500">
          Blog Archive
        </h1>

        <div className="mb-8 flex flex-wrap justify-between items-center gap-4">
          <div className="relative w-full sm:w-64">
            <input
              type="text"
              placeholder="Search posts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 bg-neutral-900 bg-opacity-80 border border-neutral-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-white placeholder-neutral-400"
            />
            <Search
              className="absolute right-3 top-2.5 text-neutral-400"
              size={18}
            />
          </div>

          <div className="relative w-full sm:w-48">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full px-3 py-2 bg-neutral-900 bg-opacity-80 border border-neutral-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-white appearance-none"
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <ChevronDown
              className="absolute right-3 top-2.5 text-neutral-400 pointer-events-none"
              size={18}
            />
          </div>

          <div className="relative w-full sm:w-48">
            <select
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              className="w-full px-3 py-2 bg-neutral-900 bg-opacity-80 border border-neutral-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-white appearance-none"
            >
              {tags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            <ChevronDown
              className="absolute right-3 top-2.5 text-neutral-400 pointer-events-none"
              size={18}
            />
          </div>
        </div>

        <div className="space-y-6">
          {filteredPosts.map((post) => (
            <article
              key={post.id}
              className="bg-neutral-900 bg-opacity-80 p-6 rounded-lg border border-neutral-700 hover:border-yellow-500 transition-colors duration-300"
            >
              <h2 className="text-2xl font-semibold mb-2 text-yellow-500">
                {post.title}
              </h2>
              <p className="text-neutral-300 mb-4">{post.excerpt}</p>
              <div className="flex flex-wrap justify-between items-center text-sm text-neutral-400">
                <span className="flex items-center">
                  <Zap size={14} className="mr-1" />
                  {post.date}
                </span>
                <span className="flex items-center">
                  <Tag size={14} className="mr-1" />
                  {post.category}
                </span>
              </div>
              <div className="mt-2 flex flex-wrap gap-2">
                {post.tags.map((tag) => (
                  <span
                    key={tag}
                    className="text-xs bg-neutral-800 text-neutral-300 px-2 py-1 rounded"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </article>
          ))}
        </div>
      </div>
      <style jsx>{`
        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: twinkle linear infinite;
        }
        @keyframes twinkle {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          50% {
            opacity: 1;
            transform: scale(1);
          }
          100% {
            opacity: 0;
            transform: scale(0.5);
          }
        }
      `}</style>
    </div>
  );
}