'use client'

import { useState, useEffect } from 'react'
import { Send } from 'lucide-react'

const StarryBackground = () => {
  useEffect(() => {
    const createStar = () => {
      const star = document.createElement('div')
      star.className = 'star'
      star.style.left = `${Math.random() * 100}vw`
      star.style.top = `${Math.random() * 100}vh`
      star.style.animationDuration = `${Math.random() * 3 + 2}s`
      star.style.width = star.style.height = `${Math.random() * 2 + 1}px`
      document.getElementById('starry-background')?.appendChild(star)
    }

    for (let i = 0; i < 200; i++) {
      createStar()
    }

    return () => {
      const bg = document.getElementById('starry-background')
      if (bg) bg.innerHTML = ''
    }
  }, [])

  return (
    <>
      <div id="starry-background" className="fixed inset-0 z-0 pointer-events-none" />
      <style>{`
        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: twinkle linear infinite;
        }
        @keyframes twinkle {
          0% { opacity: 0; transform: scale(0.5); }
          50% { opacity: 1; transform: scale(1); }
          100% { opacity: 0; transform: scale(0.5); }
        }
      `}</style>
    </>
  )
}

export default function ServicesPage() {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    try {
      const response = await fetch('https://formspree.io/f/xpwzrrja', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message,
          _subject: 'New Project Inquiry from Services Page'
        })
      })

      if (!response.ok) {
        throw new Error('Failed to send message')
      }

      setIsSubmitted(true)
      setEmail('')
      setMessage('')
    } catch (err) {
      console.error('Error:', err)
      setError('An error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="min-h-screen bg-black text-white flex flex-col justify-center items-center p-4 font-mono relative overflow-hidden">
      <StarryBackground />
      <div className="max-w-2xl w-full space-y-8 z-10">
        <h1 className="text-4xl font-bold text-center mb-8 text-yellow-500">Services</h1>
        
        <p className="text-lg text-center mb-8 text-neutral-100">
          I do commission work for companies and individuals working on projects related to my areas of interest. 
          If you think you have a cool project idea that you want to work with me on, or just have general inquiries, 
          feel free to contact me!
        </p>

        {isSubmitted ? (
          <div className="text-center p-6 bg-neutral-900 bg-opacity-80 rounded-lg border border-yellow-500 shadow-lg shadow-yellow-500/20">
            <p className="text-xl font-semibold text-yellow-500">Thank you for your message!</p>
            <p className="text-neutral-100">I'll get back to you as soon as possible.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-neutral-100 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 bg-neutral-900 bg-opacity-80 border border-neutral-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-white placeholder-neutral-400"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-neutral-100 mb-1">
                Project Idea / Message
              </label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={4}
                className="w-full px-3 py-2 bg-neutral-900 bg-opacity-80 border border-neutral-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-white placeholder-neutral-400"
                placeholder="Tell me about your project idea..."
              ></textarea>
            </div>
            {error && (
              <p className="text-red-500">{error}</p>
            )}
            <div>
              <button
                type="submit"
                disabled={loading}
                className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-colors duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {loading ? 'Sending...' : 'Send Message'}
                {!loading && <Send className="ml-2 h-4 w-4" />}
              </button>
            </div>
          </form>
        )}
      </div>
      <style jsx>{`
        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: twinkle linear infinite;
        }
        @keyframes twinkle {
          0% { opacity: 0; transform: scale(0.5); }
          50% { opacity: 1; transform: scale(1); }
          100% { opacity: 0; transform: scale(0.5); }
        }
      `}</style>
    </div>
  )
}