'use client'

import React, { Suspense, memo, useRef, useState, useEffect, useMemo, useCallback, lazy, startTransition } from 'react';
import { debounce } from 'lodash';
import { FixedSizeList } from 'react-window';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { useTexture, OrbitControls } from '@react-three/drei';
import {
  FaTwitter, FaLinkedin, FaGithub, FaInstagram, FaEnvelope, FaFolder, FaFile, FaTimes, FaChevronRight
} from 'react-icons/fa';
import { SRGBColorSpace, TextureLoader, Color } from 'three';
import * as THREE from 'three';
import { ExternalLink } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useScroll, useTransform, useSpring } from 'framer-motion';
import { hydrateRoot } from 'react-dom/client';
import { InterestImagePopup } from './components/InterestImagePopup';
import { SpinningImagesData } from './components/SpinningImagesData';
import { StarryBackground } from './components/StarryBackgroundHome';

const ProjectDetailsPopup = lazy(() => import('./components/ProjectDetailsPopup'));

<script src="http://localhost:8097"></script>

// Custom hook for globe texture
const useGlobeTexture = () => {
  return useLoader(TextureLoader, '/assets/3D/earth.jpg');
};

const preloadComponent = (component) => {
  const preloadPromise = component();
  return () => preloadPromise;
};

const SmoothScroll = ({ children }) => {
  const { scrollY } = useScroll();
  const transform = useTransform(scrollY, [0, 1], [0, 1]);
  const physics = { damping: 15, mass: 0.27, stiffness: 55 }; // Adjust these values for different feel
  const spring = useSpring(transform, physics);

  return (
    <motion.div
      style={{
        y: spring,
        willChange: 'transform'
      }}
    >
      {children}
    </motion.div>
  );
};

// CSS Styles with Keyframes
const styles = `
@keyframes fadeIn { from { opacity: 0; transform: scale(0.95); } to { opacity: 1; transform: scale(1); }}
.animate-fadeIn { animation: fadeIn 0.3s ease-out forwards; }
@keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; } 100% { opacity: 1; }}
.animate-blink {
.animate-blink { animation: blink 1s step-start infinite; }
`;

// InjectStyles Component to add keyframes to the document
const InjectStyles = memo(() => {
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = styles;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);
  return null;
});

// Popup Component
const Popup = memo(({ active, onClose, content }) => {
  if (!active) return null;
  return (
    <div 
      role="dialog" 
      aria-modal="true" 
      aria-labelledby="popup-title"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div 
        className="bg-gray-900 rounded-lg shadow-lg max-w-3xl w-full flex flex-col md:flex-row animate-fadeIn"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Image Section - Using standard img tag */}
        <div className="md:w-1/2 p-4">
          <img
            src={content.image}
            alt={content.title}
            className="rounded-lg w-full h-auto object-cover"
            loading="eager"
          />
        </div>
        <div className="md:w-1/2 p-4">
          <h2 id="popup-title" className="text-3xl font-bold mb-4 text-white">{content.title}</h2>
          <p className="text-lg text-yellow-400">{content.info}</p>
          <button onClick={onClose} className="mt-6 px-4 py-2 bg-yellow-400 text-black rounded hover:bg-yellow-300 transition-colors">
            Close
          </button>
        </div>
      </div>
    </div>
  );
});

// MessagePopup Component
function MessagePopup({ active, onClose, message }) {
  if (!active) return null;

  return (
    <div 
      role="dialog" 
      aria-modal="true" 
      className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose} // Close when clicking outside the modal
    >
      {/* Prevent click inside the modal from closing */}
      <div 
        className="bg-gray-800 bg-opacity-90 p-6 rounded-lg max-w-lg w-full shadow-lg animate-fadeIn"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-yellow-400">Personal Message</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-yellow-400 transition-colors"
            aria-label="Close Message Popup"
          >
            <FaTimes />
          </button>
        </div>
        <div className="bg-gray-700 bg-opacity-80 p-4 rounded">
          <p className="text-sm text-gray-200 whitespace-pre-wrap">{message}</p>
        </div>
      </div>
    </div>
  );
}

function WebGLErrorRecovery() {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && hasError) {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [hasError]);

  useEffect(() => {
    const handleError = () => setHasError(true);
    window.addEventListener('webglcontextlost', handleError);
    return () => window.removeEventListener('webglcontextlost', handleError);
  }, []);

  return hasError ? (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80">
      <button
        onClick={() => window.location.reload()}
        className="px-4 py-2 bg-yellow-400 text-black rounded hover:bg-yellow-300 transition-colors"
      >
        Reload 3D View
      </button>
    </div>
  ) : null;
}



// ErrorBoundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('WebGL Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-[800px] w-full bg-black flex items-center justify-center">
          <p className="text-white text-xl">
            3D visualization unavailable. Please refresh the page.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

// Research Interests Data
const researchInterests = [
  {
    id: 1,
    title: "Future of Autonomous Flight",
    image: "/assets/images/planey.jpg",
    info: "Planes taking off with no pilot, but how?",
    popupVariant: 'darker',
    popupContent: {
      title: "Autonomous Commercial Flight",
      description: "Imagine the whole process of taking a flight, from point a to point b, was completely automated. Insane, right?",
      mainImage: "/assets/images/planea.webp",
      additionalImages: [
        { src: "/assets/images/planea.webp", url: "https://sourceability.com/post/first-fully-autonomous-flight-takes-off-with-aim-to-improve-safety" },
        { src: "/assets/images/planeb.webp", url: "https://www.axios.com/2023/02/03/fully-autonomous-flight-planes" },
        { src: "/assets/images/planec.avif", url: "https://www.aviationtoday.com/2024/10/03/a350-1000-takes-the-lead-in-autonomous-flying/" }
      ],
      quote: "In November 2023, a Cessna 208B Caravan took to the skies with no pilot on board and completed an entire test flight with total autonomy",
      quoteAuthor: "Sourceability Team",
      details: "UAV technology has been drastically improving recently, leading many wondering about the next step, fully autonomous aircraft. I've provided three sources below showing how we are much closer to autonomous commercial flight than you may think! Airbus appears to be leading this field, but as we enter an era of abundant manufacturing, we will hopefully see competitors and startups that ramp up the rate of innovation.",
      links: [
        {
          title: "Check out Airbus",
          url: "https://www.airbus.com/en/innovation/future-aircraft-operations/autonomous-flight"
        }
      ]
    }
  },
  {
    id: 2,
    title: "AI and Digital Twins are changing weather systems",
    image: "/assets/images/aiweather2.jpeg",
    info: "As AI models scale, and projects like Nvidia's Earth-2 (a full realtime digital twin of the earth) continue to get better, our sensibilities around weather and climate change will dramatically improve",
    popupVariant: 'lighter',
    popupContent: {
      title: "AI and Digital Twins are changing weather systems",
      description: "We can prepare for much more advanced weather tracking systems and clear solvents to climate change in the near future",
      mainImage: "/assets/images/earth2.png",
      additionalImages: [
        { src: "/assets/images/aiweather.jpeg", url: "https://climavision.com/blog/ai-and-weather-data-revolutionizing-accurate-forecasting/#:~:text=AI%20Enhances%20Weather%20Forecasting%3A%20AI,better%20prepare%20for%20weather%20events." },
        { src: "/assets/images/aiweather.avif", url: "https://www.meteorologicaltechnologyinternational.com/news/climate-measurement/huawei-cloud-launches-ai-powered-pangu-weather-model.html" },
        { src: "/assets/images/weather.jpg", url: "https://www.climatechange.ai/" }
      ],
      quote: "",
      quoteAuthor: "",
      details: "AI systems are likely going to give us near-perfect weather tracking capability, as machines are nominally better at predicting and modeling things within chaotic systems. Recently, Nvidia showcased an impressive feat with their Earth-2 model. Earth-2 will allow us to understand weather patterns down to minute details such as wind pressure hitting buildings, effects on crops, etc. Weather prediction is about to get more accurate than ever before.",
      links: [
        {
          title: "Check out Earth-2",
          url: "https://www.nvidia.com/en-us/high-performance-computing/earth-2/"
        }
      ]
    }
  },
  {
    id: 3,
    title: "Simulation and the Future of Video Games",
    image: "/assets/images/minecraft.avif",
    info: "Videos games as they are now may never exist again. They will only get more immersive, experimental, and easier to build",
    popupVariant: 'darker',
    popupContent: {
      title: "Simulation and the Future of Video Games",
      description: "Imagine an FPS game, but you never load into the same map, it's always new. Imagine building your own world, an immersive simulation curated to your tastes. This is on the horizon.",
      mainImage: "/assets/images/doom.jpeg",
      additionalImages: [
        { src: "/assets/images/doom.jpg", url: "https://arxiv.org/pdf/2408.14837" },
        { src: "/assets/images/netfl.jpg", url: "https://wccftech.com/netflix-investing-in-generative-ai-for-games-after-closing-studios/" },
        { src: "/assets/images/genie.gif", url: "https://sites.google.com/view/genie-2024/" }
      ],
      quote: "",
      quoteAuthor: "",
      details: "Generative AI for video games is still in its infancy, but multiple advancements and calls to action have been made this year. Just a couple of weeks ago as of writing this, a company called Descart AI has created the first fully generated video games from a neural network, a recreation of minecraft. Companies are preparing for this, Netflix announced they were shuttering their game studio to fully bet on ai video games, and EA, the largest gaming company in the world has similarly gone all in on generative AI. It will be exciting to see how AI is fused into the game development process in the next few years!",
      links: [
        {
          title: "Check out the first AI video game",
          url: "https://www.decart.ai/articles/oasis-interactive-ai-video-game-model"
        }
      ]
    }
  },
  {
    id: 4,
    title: "The era of personalized entertainment",
    image: "/assets/images/plane.jpg",
    info: "Building one-person entertainment companies.",
    popupVariant: 'lighter',
    popupContent: {
      title: "Anything World",
      description: "Building one-person entertainment companies powered by curated generative AI technologies.",
      mainImage: "/assets/images/anythingworld2.webp",
      additionalImages: [
        { src: "/assets/images/plane.jpg", url: "https://runwayml.com/" },
        { src: "/assets/images/plane.jpg", url: "https://example.com/aw2" },
        { src: "/assets/images/plane.jpg", url: "https://example.com/aw3" }
      ],
      quote: "Empowering creators to dream big.",
      quoteAuthor: "AI Innovator",
      details: "Anything World is at the forefront of blending AI with creative entrepreneurship. Our platform enables individuals to harness the power of generative AI to build scalable entertainment ventures single-handedly.",
      links: [
        {
          title: "View Project",
          url: "https://github.com/prestoncreed/Anything-World"
        }
      ]
    }
  }
];

// Typing Effect Hook
const useTypingEffect = (text, startDelay = 0, speed = 30) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const timeoutRef = useRef(null);
  const mountedRef = useRef(true);
  
  useEffect(() => {
    mountedRef.current = true;
    
    const startTyping = async () => {
      try {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Reset state
        setDisplayedText('');
        setIsComplete(false);

        // Wait for initial delay
        await new Promise(resolve => setTimeout(resolve, startDelay));
        
        if (!mountedRef.current) return;

        // Type each character
        for (let i = 0; i <= text.length; i++) {
          if (!mountedRef.current) break;
          
          timeoutRef.current = setTimeout(() => {
            if (!mountedRef.current) return;
            
            setDisplayedText(text.slice(0, i));
            
            if (i === text.length) {
              setIsComplete(true);
            }
          }, speed * i);
        }
      } catch (error) {
        console.error('Typing effect error:', error);
      }
    };

    startTyping();

    return () => {
      mountedRef.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [text, speed, startDelay]);

  return { displayedText, isComplete };
};

const usePreloadTexture = (url) => {
  const textureRef = useRef(null);
  
  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.loadAsync(url).then(texture => {
      texture.colorSpace = SRGBColorSpace;
      texture.minFilter = THREE.LinearFilter;
      texture.generateMipmaps = false;
      textureRef.current = texture;
    });
    
    return () => {
      if (textureRef.current) {
        textureRef.current.dispose();
      }
    };
  }, [url]);
  
  return textureRef;
};

const Globe = memo(function Globe() {
  const meshRef = useRef(null);
  const textureRef = useRef(null);
  
  // Reduce geometry segments for better performance
  const sphereGeometry = useMemo(() => new THREE.SphereGeometry(1.8, 32, 32), []); // Reduced from 64,64
  
  // Pre-create material to avoid recreation
  const material = useMemo(() => new THREE.MeshStandardMaterial({
    roughness: 1,
    metalness: 0,
    emissive: new THREE.Color(0xffffff),
    emissiveIntensity: 0.5
  }), []);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    const loadTexture = async () => {
      try {
        const texture = await loader.loadAsync('/assets/3D/earth.jpg');
        texture.colorSpace = SRGBColorSpace;
        texture.minFilter = THREE.LinearFilter; // Reduced texture filtering complexity
        texture.generateMipmaps = false; // Disable mipmaps for better performance
        
        if (meshRef.current) {
          material.map = texture;
          material.emissiveMap = texture;
          material.needsUpdate = true;
        }
        textureRef.current = texture;
      } catch (error) {
        console.error('Error loading texture:', error);
      }
    };
    
    loadTexture();
    
    return () => {
      if (textureRef.current) {
        textureRef.current.dispose();
      }
    };
  }, [material]);

  // Reduce rotation speed calculation frequency
  const rotationSpeed = useRef(0.05);
  useFrame((_, delta) => {
    if (meshRef.current) {
      meshRef.current.rotation.y += delta * rotationSpeed.current;
    }
  });

  return (
    <mesh ref={meshRef} geometry={sphereGeometry} material={material}>
      <ambientLight intensity={1} />
      <directionalLight intensity={2} position={[5, 5, 5]} />
    </mesh>
  );
});

// Loading Progress component with smooth transition
const LoadingProgress = ({ progress }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="absolute inset-0 flex items-center justify-center bg-black"
  >
    <div className="w-64 h-2 bg-gray-800 rounded-full overflow-hidden">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.5 }}
        className="h-full bg-yellow-400"
      />
    </div>
  </motion.div>
);

// Performance monitoring component
const PerformanceMonitor = () => {
  useEffect(() => {
    let frameCount = 0;
    let lastTime = performance.now();
    
    const checkPerformance = () => {
      frameCount++;
      const currentTime = performance.now();
      
      if (currentTime - lastTime >= 1000) {
        const fps = Math.round((frameCount * 1000) / (currentTime - lastTime));
        if (fps < 30) {
          console.warn('Low FPS detected:', fps);
        }
        frameCount = 0;
        lastTime = currentTime;
      }
      
      requestAnimationFrame(checkPerformance);
    };
    
    const handle = requestAnimationFrame(checkPerformance);
    return () => cancelAnimationFrame(handle);
  }, []);
  
  return null;
};

const ProjectCard = memo(function ProjectCard({ project, index, isActive, onMouseEnter }) {
  return (
    <div
      className={`relative overflow-hidden rounded-lg transition-all duration-300 ${
        index === project.length - 1 ? 'col-span-2' : ''
      } ${isActive ? 'ring-2 ring-yellow-400' : 'opacity-60'}`}
      onMouseEnter={() => {
        // Debounce state update using requestAnimationFrame
        window.requestAnimationFrame(() => onMouseEnter(index));
      }}
    >
      <img
        src={project.image}
        alt={project.title}
        className="object-cover w-full h-full"
        loading={index > 2 ? "lazy" : "eager"} // Load first 3 images eagerly
        decoding="async"
      />
    </div>
  );
});

const GlobeContent = memo(function GlobeContent({ onImageClick }) {
  const { ref: globeRef, inView: isGlobeVisible } = useInView({
    threshold: 0,
    rootMargin: '100px 0px',
    triggerOnce: false,
    skip: false
  });

  const [shouldRender, setShouldRender] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isGlobeVisible) {
      setShouldRender(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    } else {
      timeoutRef.current = setTimeout(() => setShouldRender(false), 1000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isGlobeVisible]);

  return (
    <div ref={globeRef} className="w-full h-full">
      {shouldRender && (
        <Suspense fallback={
          <div className="h-[800px] w-full bg-black flex items-center justify-center">
            <p className="text-white">Loading 3D Scene...</p>
          </div>
        }>
          <Canvas
            gl={{
              antialias: true,
              alpha: false,
              powerPreference: "high-performance",
              failIfMajorPerformanceCaveat: false,
              preserveDrawingBuffer: true
            }}
            dpr={window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio}
            performance={{ min: 0.5 }}
            camera={{
              position: [0, 2, 6],
              fov: 45,
              near: 0.1,
              far: 1000
            }}
            style={{ background: '#000' }}
            dispose={null}
          >
            <color attach="background" args={['#000']} />
            <fog attach="fog" args={['#000', 5, 15]} />
            <Suspense fallback={null}>
              <InterestsGlobe onImageClick={onImageClick} />
            </Suspense>
            <OrbitControls
              enableZoom={false}
              enablePan={true}
              screenSpacePanning={false}
              minPolarAngle={Math.PI / 4}
              maxPolarAngle={(3 * Math.PI) / 4}
              maxDistance={10}
              minDistance={3}
              enableDamping={true}
              dampingFactor={0.05}
              rotateSpeed={0.5}
            />
          </Canvas>
        </Suspense>
      )}
    </div>
  );
});


// InterestImage Component - Replace the entire InterestImage component with this
function InterestImage({ angle, image, title, info, popupContent, popupVariant, onClick }) {
  const meshRef = useRef();
  const positionRef = useRef({ x: 0, y: 0, z: 0 });
  
  // Pre-compute constants
  const globeRadius = 1.8;
  const distanceFromGlobe = -0.7;
  const radius = useMemo(() => globeRadius + distanceFromGlobe, []);
  
  // Pre-compute geometry with reduced segments
  const cylinderGeometry = useMemo(() => new THREE.CylinderGeometry(
    1.2, 1.2, 1.2, 16, 1, true, -Math.PI / 4, Math.PI / 2
  ), []); // Reduced from 32 segments for better performance

  // Pre-compute material properties
  const materialProperties = useMemo(() => ({
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.9,
    metalness: 0.1,
    roughness: 0.8,
    envMapIntensity: 0.2,
    clearcoat: 0.5,
    clearcoatRoughness: 0.3
  }), []);

  // Optimize texture loading
  const texture = useLoader(THREE.TextureLoader, image, loader => {
    loader.minFilter = THREE.LinearFilter;
    loader.magFilter = THREE.LinearFilter;
    loader.generateMipmaps = false; // Disable mipmaps for better performance
  });

  useEffect(() => {
    if (texture) {
      texture.needsUpdate = true;
    }
  }, [texture]);

  // Calculate position only when angle changes
  useEffect(() => {
    positionRef.current = {
      x: Math.sin(angle) * radius,
      y: 0,
      z: Math.cos(angle) * radius
    };
    
    if (meshRef.current) {
      const { x, y, z } = positionRef.current;
      meshRef.current.position.set(x, y, z);
      meshRef.current.lookAt(x * 2, y * 2, z * 2);
      meshRef.current.rotateX(Math.PI / 32);
    }
  }, [angle, radius]);

  return (
    <mesh
      ref={meshRef}
      geometry={cylinderGeometry}
      onClick={() => onClick({
        popupContent: {
          ...popupContent,
          mainImage: image, // Ensure mainImage is set
          title: title,    // Ensure title is set
          description: info // Use info as description if no description provided
        },
        popupVariant: popupVariant || 'darker'
      })}
      onPointerOver={(e) => e.object?.scale.setScalar(1.05)}
      onPointerOut={(e) => e.object?.scale.setScalar(1)}
    >
      <meshPhysicalMaterial
        {...materialProperties}
        map={texture}
      />
    </mesh>
  );
}

// InterestsGlobe Component - Replace the entire InterestsGlobe component with this
const InterestsGlobe = memo(function InterestsGlobe({ onImageClick }) {
  const groupRef = useRef(null);
  const frameCount = useRef(0);
  const lastTime = useRef(Date.now());
  const animationFrameRef = useRef(null);
  const isDisposed = useRef(false);
  
  // Pre-load textures
  const earthTexture = usePreloadTexture('/assets/3D/earth.jpg');

  useFrame((_, delta) => {
    if (isDisposed.current) return;

    frameCount.current++;
    const currentTime = Date.now();
    
    // Limit updates to 30fps for better performance
    if (currentTime - lastTime.current >= 1000 / 30) {
      if (groupRef.current) {
        groupRef.current.rotation.y += delta * 0.05;
      }
      frameCount.current = 0;
      lastTime.current = currentTime;
    }

    animationFrameRef.current = requestAnimationFrame(() => {
      if (groupRef.current) {
        groupRef.current.rotation.y += delta * 0.05;
      }
    });
  });

  // Clean up resources when unmounting
  useEffect(() => {
    return () => {
      isDisposed.current = true;
      
      if (earthTexture.current) {
        earthTexture.current.dispose();
      }

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      if (groupRef.current) {
        groupRef.current.traverse((child) => {
          if (child.material) {
            child.material.dispose();
          }
          if (child.geometry) {
            child.geometry.dispose();
          }
        });
      }
    };
  }, []);

  const renderedInterests = useMemo(() => 
    researchInterests.map((interest, index) => (
      <InterestImage
        key={index}
        angle={(index * (2 * Math.PI)) / researchInterests.length}
        image={interest.image}
        title={interest.title}
        info={interest.info}
        popupContent={interest.popupContent}
        popupVariant={interest.popupVariant}
        onClick={onImageClick}
      />
    )), [onImageClick]);

  return (
    <group ref={groupRef}>
      <ambientLight intensity={0.5} />
      <directionalLight
        intensity={1.5}
        position={[5, 5, 5]}
        castShadow={false}
      />
      <Globe earthTexture={earthTexture.current} />
      {renderedInterests}
    </group>
  );
});

// Main Component
export default function Component() {
  const [activeProject, setActiveProject] = useState(0);
  const [popupContent, setPopupContent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showProjectDetails, setShowProjectDetails] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);
const [activeFolder, setActiveFolder] = useState(null);
const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false);

  const mainText = "Welcome! My name's Preston Creed. I'm excited for the future and love to build. AI will help us launch businesses overnight, craft new experiences, and build digital worlds. I plan to be there every step of the way, building things that matter for the next generation of thinkers.";
  const ctaText = "Come check out my projects and art. I also frequently write blogs here, so give that a look!";
  const { displayedText: typedMain, isComplete: mainComplete } = useTypingEffect(mainText, 0, 30);
const { displayedText: typedCTA, isComplete: ctaComplete } = useTypingEffect(
  ctaText, 
  mainComplete ? 0 : mainText.length * 30,
  30
);

  // Render optimization
  const renderIntroSection = useMemo(() => (
    <section className="mb-20">
      <p className="text-2xl mb-6 min-h-[1em]">
        {typedMain}
        {!mainComplete && <span className="animate-blink">|</span>}
      </p>
      <p className="text-2xl mb-6 min-h-[1em]">
        {mainComplete && typedCTA}
        {mainComplete && !ctaComplete && <span className="animate-blink">|</span>}
      </p>
    </section>
  ), [typedMain, typedCTA, mainComplete, ctaComplete]);

  const projects = useMemo(() => [
    { id: 1, title: "Redmeadow", description: "A dashboard platform designed for creatives and entrepreneurs, to manage swarms of AI Agents", image: "/assets/images/digitalworld.jpg" },
    { id: 2, title: "Digital Architects", description: "Currently working on Digital Architects. The goal is to have spatially aware AI Agents that act as architects within game engine environments. They're tasked to create 3D models and properly build out locations.", image: "/assets/images/digitalworld.jpg" },
    { id: 3, title: "Anything World", description: "Building one-person entertainment companies", image: "/assets/images/anythingworld2.webp" },
  ], []);

  const fileSystem = [
    { 
      id: 1, 
      name: "AI Agents", 
      type: "folder",
      children: [
        { 
          id: 11, 
          name: "Digital Architects", 
          type: "file", 
          content: "Digital Architects are AI Agents that act as architects of simulations in game engines. They are designed to create contextually correct 3D models (building, objects, characters, etc.) based on user queries or predefined rules. They are then trained to correctly place these models at the correct coordinates within your environment. Digital Architects take are surprisingly efficient in compute and are programmed to work together on your goal.",
          github_url: "https://github.com/prestoncreed/digitalarchitects"
        },
        { 
          id: 12, 
          name: "Redmeadow AI", 
          type: "file", 
          content: "Redmeadow AI is a platform designed for creatives and entrepreneurs to manage swarms of AI Agents working on projects.",
          github_url: "https://github.com/prestoncreed"
        },
      ]
    },
    { 
      id: 2, 
      name: "Game Development", 
      type: "folder",
      children: [
        { 
          id: 21, 
          name: "tbd", 
          type: "file", 
          content: "tbd",
          github_url: "https://github.com/prestoncreed" // Add actual URL when available
        },
      ]
    },
    { 
      id: 3, 
      name: "Generative AI", 
      type: "folder",
      children: [
        { 
          id: 31, 
          name: "Anything World", 
          type: "file", 
          content: "An experimental project focused on exploring the future of one-person entertainment companies powered by curated generative AI technologies.",
          github_url: "https://github.com/prestoncreed"
        },
      ]
    },
  ];

  const toggleFolder = (id) => {
    setExpandedFolders(prev => 
      prev.includes(id) ? prev.filter(folderId => folderId !== id) : [...prev, id]
    );
  };

  // Handler for image clicks with new popup system
  const handleImageClick = (imageData) => {
    setSelectedImage(imageData);
  };

  // Handler to close popup
  const closePopup = () => {
    setPopupContent(null);
  };

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      <StarryBackground />
      {/* Message Bar - Outside SmoothScroll - Updated styling */}
      <div className="w-full bg-transparent">
        <div className="container mx-auto px-4">
          <div 
            className="flex items-center space-x-2 py-2 cursor-pointer hover:bg-white/5 transition-all duration-300"
            onClick={() => setIsMessagePopupOpen(true)}
          >
            <FaEnvelope 
              size={16} 
              className="text-yellow-400"
            />
            <span className="text-sm text-yellow-400">
              Message from the Creator
            </span>
          </div>
        </div>
      </div>
  
      {/* Main Content - Inside SmoothScroll */}
      <SmoothScroll>
        <div className="max-w-5xl mx-auto px-4 py-6">
          {/* Profile Section */}
          <section className="mb-16 text-center relative mt-2" data-hydrate="profile">
            <img
              src="/assets/images/profile.jpeg"
              alt="Preston Creed"
              className="rounded-full border-4 border-yellow-400 mx-auto mb-6 w-52 h-52"
            />
            <h1 className="text-6xl font-bold mb-4">Preston Creed</h1>
            <p className="text-2xl text-yellow-400 mb-6">Welcome to the intelligence age</p>
            <div className="flex justify-center space-x-6 mb-8">
              <a href="mailto:creedsalesdivision@gmail.com" className="text-3xl hover:text-yellow-400 transition-colors">
                <FaEnvelope />
              </a>
              <a href="https://x.com/theprestoncreed" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-blue-400 transition-colors">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/in/preston-creed-4520672ab/" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-blue-700 transition-colors">
                <FaLinkedin />
              </a>
              <a href="https://github.com/prestoncreed" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-purple-600 transition-colors">
                <FaGithub />
              </a>
              <a href="https://instagram.com/prestoncreed_" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-pink-600 transition-colors">
                <FaInstagram />
              </a>
            </div>
          </section>
  
          {/* Introduction Section */}
{renderIntroSection}
  
          {/* Projects Section */}
          <section className="mb-20">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="grid grid-cols-2 gap-4">
                {projects.map((project, index) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    index={index}
                    isActive={index === activeProject}
                    onMouseEnter={setActiveProject}
                  />
                ))}
              </div>
  
              <div className="space-y-6">
                <h3 className="text-5xl font-bold text-yellow-400">{projects[activeProject].title}</h3>
                <p className="text-2xl">{projects[activeProject].description}</p>
                <div className="flex gap-4">
                  <a
                    href="https://discord.gg/ZvrkSZbh8Z"
                    className="inline-flex items-center px-8 py-4 bg-yellow-400 text-black text-xl font-bold rounded hover:bg-yellow-300 transition-colors"
                  >
                    Check it out
                  </a>
                  <button
                    onClick={() => setShowProjectDetails(true)}
                    className="inline-flex items-center px-8 py-4 border-2 border-yellow-400 text-yellow-400 text-xl font-bold rounded hover:bg-yellow-400 hover:text-black transition-colors"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </section>
  
          {/* Project Details Popup */}
          {showProjectDetails && (
  <ProjectDetailsPopup
    active={showProjectDetails}
    onClose={() => setShowProjectDetails(false)}
    project={projects[activeProject]}
  />
)}{/* VS Code section */}
          <div className="mt-16">
            <h3 
              className="text-4xl font-bold mb-6 text-yellow-400" 
              style={{ 
                fontFamily: "'Inter', sans-serif",
                letterSpacing: '-0.02em'
              }}
            >
              My Work
            </h3>
            <div className="p-6 rounded-lg bg-black border border-gray-800">
              <div className="flex items-center justify-between mb-6">
                <div className="flex space-x-2">
                  <div className="w-4 h-4 rounded-full bg-red-500"></div>
                  <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                  <div className="w-4 h-4 rounded-full bg-green-500"></div>
                </div>
                <div className="text-lg text-gray-400">projects</div>
              </div>
              <div className="space-y-4 font-mono text-lg">
                {fileSystem.map((folder) => (
                  <div key={folder.id}>
                    <button
                      onClick={() => toggleFolder(folder.id)}
                      className="flex items-center space-x-2 w-full text-left hover:text-yellow-400 transition-colors"
                    >
                      <FaChevronRight 
                        className={`transform transition-transform ${
                          expandedFolders.includes(folder.id) ? 'rotate-90' : ''
                        }`} 
                      />
                      <FaFolder className="text-yellow-400" />
                      <span>{folder.name}</span>
                    </button>
                    {expandedFolders.includes(folder.id) && (
                      <div className="ml-6 mt-2 space-y-2">
                        {folder.children.map((file) => (
                          <button
                            key={file.id}
                            onClick={() => setActiveFolder(file)}
                            className="flex items-center space-x-2 w-full text-left hover:text-yellow-400 transition-colors"
                          >
                            <FaFile className="text-gray-400" />
                            <span>{file.name}</span>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
  
          {/* 3D Globe Section */}
          <section className="relative mt-12">
  <div className="w-screen relative left-1/2 right-1/2 -mx-[50vw]">
    <div className="relative h-[800px] w-full bg-black flex items-center justify-center">
      <ErrorBoundary>
        <Suspense fallback={
          <div className="h-[800px] w-full bg-black flex items-center justify-center">
            <p className="text-white">Loading 3D Scene...</p>
          </div>
        }>
          <GlobeContent onImageClick={handleImageClick} />
          <WebGLErrorRecovery />
        </Suspense>
      </ErrorBoundary>
    </div>
  </div>
</section>
</div>
    </SmoothScroll>

    {/* All modals and popups go here - outside SmoothScroll */}
    {activeFolder && (
  <div
    role="dialog"
    aria-modal="true"
    aria-labelledby="popup-title"
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    onClick={() => setActiveFolder(null)} // Close when clicking outside the modal
  >
    <div
      className="bg-gray-900 p-6 rounded-lg max-w-2xl w-full relative"
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h3 id="popup-title" className="text-2xl font-bold text-yellow-400">
          {activeFolder.name}
        </h3>
        <button
          onClick={() => setActiveFolder(null)}
          className="text-gray-400 hover:text-white transition-colors"
          aria-label="Close File Content Modal"
        >
          <FaTimes />
        </button>
      </div>
      
      {/* Content */}
      <div className="bg-gray-800 p-4 rounded">
        <pre className="text-lg overflow-x-auto whitespace-pre-wrap text-gray-200">
          {activeFolder.content}
        </pre>
        
        {/* GitHub Button */}
        {activeFolder.github_url && (
          <a
            href={activeFolder.github_url}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 inline-flex items-center px-5 py-2 bg-black text-gray-300 border border-gray rounded-lg hover:bg-gray-700 transition-colors duration-300 cursor-pointer shadow-md text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
            aria-label={`View ${activeFolder.name} on GitHub`}
          >
            <FaGithub className="mr-2" />
            View on GitHub
          </a>
        )}
      </div>
    </div>
  </div>
)}

    {/* Add the new popup component */}
    <InterestImagePopup
        content={selectedImage?.popupContent}
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        variant={selectedImage?.popupVariant}
      />

    <MessagePopup
      active={isMessagePopupOpen}
      onClose={() => setIsMessagePopupOpen(false)}
      message={`Welcome to my page! As you can tell, I'm really excited about the future and love to build cool things. I am new to the technology space as of 2024, but have decided that accelerating technology is something I'm genuinely interested in, and it aligns with the beliefs I've had my entire life. Some might say I'm pretty good at it too!

My page is a work in progress and is intended to capture my journey through time as I build and experiment with new things. Expect the content on here to grow exponentially from what you see right now. That's a promise!

This message will stay up for as long as I see it to be relevant and is intended to say I'm new here and have lots to learn. I consider myself to be a serial creative and lover of new experiences, and I want to build things to help other people like myself thrive and prosper in their own creativity.

With that said, I'll now let my work speak for itself. Enjoy the site!`}
    />
  </div>
);}