import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaTwitter, FaLinkedin, FaGithub, FaInstagram, FaEnvelope, FaRocket, FaRobot } from 'react-icons/fa';

export default function Layout() {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-black text-white font-mono">
      {/* Header */}
      <header className="border-b border-white">
        <nav className="container mx-auto px-4 py-4">
          <ul className="flex justify-between items-center">
            <li>
              <Link to="/" className="text-2xl font-bold hover:text-yellow-400 transition-colors">
                [preston creed]
              </Link>
            </li>
            <div className="flex space-x-4">
              <li>
                <Link to="/blog" className="hover:text-yellow-400 transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/projects" className="hover:text-yellow-400 transition-colors">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/art" className="hover:text-yellow-400 transition-colors">
                  Art
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-yellow-400 transition-colors">
                  Services
                </Link>
              </li>
            </div>
          </ul>
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <Outlet /> {/* This is where child routes will render */}
      </main>

      {/* Footer */}
      <footer className="border-t border-white mt-8">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <span>© {new Date().getFullYear()} Preston Creed</span>
          <span className="text-xl">{currentTime}</span>
        </div>
      </footer>
    </div>
  );
}