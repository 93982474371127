'use client';

import { useEffect } from 'react';

export function StarryBackground() {
  useEffect(() => {
    // Create stars (same as before)
    const createStar = () => {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.left = `${Math.random() * 100}vw`;
      star.style.top = `${Math.random() * 100}vh`;
      star.style.animationDuration = `${Math.random() * 3 + 2}s`;
      star.style.width = star.style.height = `${Math.random() * 2 + 1}px`;
      document.getElementById('starry-background')?.appendChild(star);
    };

    for (let i = 0; i < 200; i++) {
      createStar();
    }

    const createEtherealOrb = () => {
      const orbContainer = document.createElement('div');
      orbContainer.className = 'orb-container';
      
      const divineCore = document.createElement('div');
      divineCore.className = 'divine-core';
      
      const celestialLayer = document.createElement('div');
      celestialLayer.className = 'celestial-layer';
      
      const etherealField = document.createElement('div');
      etherealField.className = 'ethereal-field';
      
      const divineGlow = document.createElement('div');
      divineGlow.className = 'divine-glow';

      const mysticalAura = document.createElement('div');
      mysticalAura.className = 'mystical-aura';
      
      orbContainer.appendChild(divineGlow);
      orbContainer.appendChild(etherealField);
      orbContainer.appendChild(celestialLayer);
      orbContainer.appendChild(mysticalAura);
      orbContainer.appendChild(divineCore);
      
      document.getElementById('starry-background')?.appendChild(orbContainer);

      setTimeout(() => {
        orbContainer.remove();
      }, 6000);
    };

    setTimeout(createEtherealOrb, 10000);

    return () => {
      const bg = document.getElementById('starry-background');
      if (bg) bg.innerHTML = '';
    };
  }, []);

  return (
    <>
      <div id="starry-background" className="fixed inset-0 z-0 pointer-events-none overflow-hidden" />
      <style jsx>{`
        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: twinkle linear infinite;
        }

        @keyframes twinkle {
          0% { opacity: 0; transform: scale(0.5); }
          50% { opacity: 1; transform: scale(1); }
          100% { opacity: 0; transform: scale(0.5); }
        }

        .orb-container {
          position: fixed;
          left: -200px;
          top: -200px;
          width: 100px;
          height: 100px;
          animation: orb-movement 6s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
          pointer-events: none;
          z-index: 1;
        }

        .divine-core {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: radial-gradient(
            circle at 45% 45%,
            rgba(255, 255, 255, 1) 0%,
            rgba(240, 240, 255, 0.9) 30%,
            rgba(200, 220, 255, 0.6) 60%,
            transparent 100%
          );
          filter: brightness(1.5) contrast(1.2);
          mix-blend-mode: screen;
          animation: core-pulse 6s ease-in-out infinite;
          transform-origin: center;
          box-shadow: 
            0 0 60px rgba(255, 255, 255, 0.5),
            0 0 100px rgba(200, 220, 255, 0.3);
        }

        .celestial-layer {
          position: absolute;
          width: 140%;
          height: 140%;
          left: -20%;
          top: -20%;
          border-radius: 50%;
          background: radial-gradient(
            circle at center,
            transparent 0%,
            rgba(230, 240, 255, 0.2) 40%,
            rgba(180, 200, 255, 0.4) 60%,
            rgba(140, 180, 255, 0.1) 70%,
            transparent 100%
          );
          filter: blur(10px);
          animation: celestial-rotate 6s linear infinite;
          transform-origin: center;
          mix-blend-mode: screen;
        }

        .ethereal-field {
          position: absolute;
          width: 180%;
          height: 180%;
          left: -40%;
          top: -40%;
          border-radius: 50%;
          background: radial-gradient(
            circle at center,
            transparent 30%,
            rgba(200, 220, 255, 0.1) 60%,
            rgba(160, 180, 255, 0.2) 70%,
            transparent 100%
          );
          filter: blur(15px);
          animation: ethereal-pulse 3s ease-in-out infinite;
          transform-origin: center;
          mix-blend-mode: screen;
        }

        .divine-glow {
          position: absolute;
          width: 200%;
          height: 200%;
          left: -50%;
          top: -50%;
          border-radius: 50%;
          background: radial-gradient(
            circle at center,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(200, 220, 255, 0.05) 50%,
            transparent 70%
          );
          filter: blur(20px);
          animation: divine-pulse 4s ease-in-out infinite;
          transform-origin: center;
          mix-blend-mode: screen;
        }

        .mystical-aura {
          position: absolute;
          width: 160%;
          height: 160%;
          left: -30%;
          top: -30%;
          background: conic-gradient(
            from 0deg,
            transparent,
            rgba(180, 200, 255, 0.1),
            rgba(200, 220, 255, 0.2),
            rgba(230, 240, 255, 0.1),
            transparent
          );
          border-radius: 50%;
          filter: blur(10px);
          animation: mystical-rotation 8s linear infinite;
          mix-blend-mode: screen;
        }

        @keyframes orb-movement {
          0% {
            transform: translate(0, 30vh) scale(0.8);
            filter: brightness(0);
          }
          10% {
            transform: translate(10vw, 35vh) scale(1);
            filter: brightness(1);
          }
          45% {
            transform: translate(45vw, 45vh) scale(1.1);
          }
          50% {
            transform: translate(50vw, 50vh) scale(1.3);
            filter: brightness(1.8);
          }
          55% {
            transform: translate(55vw, 55vh) scale(1.1);
            filter: brightness(1);
          }
          90% {
            transform: translate(90vw, 70vh) scale(1);
            filter: brightness(1);
          }
          100% {
            transform: translate(calc(100vw + 300px), 80vh) scale(0.8);
            filter: brightness(1);
          }
        }

        @keyframes core-pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.1); filter: brightness(1.3); }
        }

        @keyframes celestial-rotate {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        @keyframes ethereal-pulse {
          0%, 100% { transform: scale(1); opacity: 0.5; }
          50% { transform: scale(1.1); opacity: 0.8; }
        }

        @keyframes divine-pulse {
          0%, 100% { transform: scale(1); opacity: 0.3; }
          50% { transform: scale(1.2); opacity: 0.6; }
        }

        @keyframes mystical-rotation {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(-360deg); }
        }
      `}</style>
    </>
  );
}