'use client'

import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown, ExternalLink, Github, Code, Cpu, Globe, X, Folder } from 'lucide-react'

const ItemCard = ({ item, onClick }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="group bg-gradient-to-br from-zinc-900 to-black rounded-lg overflow-hidden cursor-pointer shadow-lg shadow-yellow-500/10 hover:shadow-yellow-500/20 transition-all duration-300"
      onClick={onClick}
    >
      <div className="relative h-48">
        <img src={item.image} alt={item.title} className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105" />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70"></div>
        <h2 className="absolute bottom-2 left-2 text-xl font-bold text-yellow-400 group-hover:text-yellow-300 transition-colors duration-300">{item.title}</h2>
      </div>
      <div className="p-4">
        <p className="text-gray-300 mb-4 text-sm">{item.description}</p>
        <div className="flex flex-wrap gap-2 mb-4">
          <span className="px-2 py-1 bg-yellow-400 text-black text-xs font-semibold rounded-full">
            {item.category}
          </span>
          {item.tags.map(tag => (
            <span key={tag} className="px-2 py-1 bg-zinc-800 text-yellow-400 text-xs font-semibold rounded-full">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  )
}

const ItemPopup = ({ item, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.95, y: 20 }}
        transition={{ duration: 0.3 }}
        className="bg-gradient-to-br from-zinc-900 to-black rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto shadow-lg shadow-yellow-500/20"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-2xl font-bold text-yellow-400">{item.title}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white transition-colors duration-200">
            <X size={24} />
          </button>
        </div>
        <img src={item.image} alt={item.title} className="w-full h-64 object-cover rounded-lg mb-6" />
        <p className="text-gray-300 mb-6 text-base leading-relaxed">{item.longDescription}</p>
        <div className="flex flex-wrap gap-2 mb-6">
          <span className="px-3 py-1 bg-yellow-400 text-black text-sm font-semibold rounded-full">
            {item.category}
          </span>
          {item.tags.map(tag => (
            <span key={tag} className="px-3 py-1 bg-zinc-800 text-yellow-400 text-sm font-semibold rounded-full">
              {tag}
            </span>
          ))}
        </div>
        {item.testimonial && (
          <blockquote className="border-l-4 border-yellow-400 pl-4 mb-6 italic text-gray-300">
            "{item.testimonial}"
          </blockquote>
        )}
        <div className="flex flex-wrap gap-4">
          {item.liveLink && (
            <a
              href={item.liveLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 bg-yellow-400 text-black rounded-full hover:bg-yellow-300 transition-colors duration-200"
            >
              <ExternalLink size={18} className="mr-2" />
              Live Demo
            </a>
          )}
          {item.githubLink && (
            <a
              href={item.githubLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 bg-zinc-800 text-yellow-400 rounded-full hover:bg-zinc-700 transition-colors duration-200"
            >
              <Github size={18} className="mr-2" />
              GitHub
            </a>
          )}
          {item.websiteLink && (
            <a
              href={item.websiteLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 bg-zinc-800 text-yellow-400 rounded-full hover:bg-zinc-700 transition-colors duration-200"
            >
              <Globe size={18} className="mr-2" />
              Website
            </a>
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}

const GlowingTitle = ({ children }) => {
  const [glowOpacity, setGlowOpacity] = useState(0.5)

  useEffect(() => {
    const interval = setInterval(() => {
      setGlowOpacity((prev) => (prev === 0.5 ? 0.7 : 0.5))
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <h1 className="text-4xl font-bold mb-8 text-center text-yellow-400 relative">
      {children}
      <span
        className="absolute inset-0 bg-yellow-400 filter blur-md z-[-1] transition-opacity duration-2000"
        style={{ opacity: glowOpacity }}
      ></span>
    </h1>
  )
}

export default function ProjectsPage({ projectItems = [] }) {
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [selectedTag, setSelectedTag] = useState(null)
  const [isGridView, setIsGridView] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null)

  const categories = ['All', ...new Set(projectItems.map(item => item.category))]
  const allTags = Array.from(new Set(projectItems.flatMap(item => item.tags)))

  const filteredItems = projectItems.filter(item => 
    (selectedCategory === 'All' || item.category === selectedCategory) &&
    (!selectedTag || item.tags.includes(selectedTag))
  )

  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <GlowingTitle>Projects</GlowingTitle>

        <div className="mb-8 flex flex-wrap justify-center items-center gap-4">
          <div className="relative w-64">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full px-4 py-2 bg-zinc-900 rounded-full text-white appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              aria-label="Filter by category"
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <Folder className="absolute right-3 top-2.5 text-yellow-400 pointer-events-none" size={20} />
          </div>
          <div className="relative w-64">
            <select
              value={selectedTag || ''}
              onChange={(e) => setSelectedTag(e.target.value || null)}
              className="w-full px-4 py-2 bg-zinc-900 rounded-full text-white appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              aria-label="Filter by tag"
            >
              <option value="">All Tags</option>
              {allTags.map(tag => (
                <option key={tag} value={tag}>{tag}</option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-2.5 text-yellow-400 pointer-events-none" size={20} />
          </div>
          <button
            onClick={() => setIsGridView(!isGridView)}
            className="p-2 bg-zinc-900 text-yellow-400 rounded-full hover:bg-zinc-800 transition-colors duration-200"
            aria-label={isGridView ? "Switch to list view" : "Switch to grid view"}
          >
            {isGridView ? <Code size={20} /> : <Cpu size={20} />}
          </button>
        </div>

        <AnimatePresence>
          <motion.div
            layout
            className={`grid gap-10 ${isGridView ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}
          >
            {filteredItems.map((item) => (
              <ItemCard 
                key={item.id} 
                item={item} 
                onClick={() => setSelectedItem(item)}
              />
            ))}
          </motion.div>
        </AnimatePresence>

        <AnimatePresence>
          {selectedItem && (
            <ItemPopup 
              item={selectedItem} 
              onClose={() => setSelectedItem(null)} 
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}