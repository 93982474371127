import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home'; // Updated import with uppercase 'H'
import Blog from './pages/Blog';
import Projects from './pages/Projects';
import Art from './pages/Art';
import Services from './pages/Services';
import ModalBlogPost from './components/ModalBlogPost'; // Import the ModalBlogPost component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ModalSwitch />
      <ToastContainer />
    </Router>
  );
}

// ModalSwitch Component to handle modal routing
function ModalSwitch() {
  const location = useLocation();
  const state = location.state;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        {/* Parent Route with Layout */}
        <Route path="/" element={<Layout />}>
          {/* Index Route for Home Page */}
          <Route index element={<Home />} />

          {/* Blog Routes */}
          <Route path="blog" element={<Blog />} />

          {/* Other Routes */}
          <Route path="projects" element={<Projects />} />
          <Route path="art" element={<Art />} />
          <Route path="services" element={<Services />} />
        </Route>
      </Routes>

      {/* Show the modal when a background page is set */}
      {state?.backgroundLocation && (
        <Routes>
          <Route path="blog/:id" element={<ModalBlogPost />} />
        </Routes>
      )}
    </>
  );
}

export default App;