'use client'

import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, Quote, ExternalLink } from 'lucide-react'

/**
 * PopupContent defines the structure of the content passed to the popup.
 * 
 * @typedef {Object} PopupContent
 * @property {string} title - The title of the popup.
 * @property {string} description - The main description text.
 * @property {string} mainImage - URL of the main image.
 * @property {Array<{ src: string, url: string }>} additionalImages - Array of additional image objects.
 * @property {string} [quote] - Optional quote text.
 * @property {string} [quoteAuthor] - Author of the quote.
 * @property {string} [details] - Detailed information.
 * @property {Array<{ title: string, url: string }>} [links] - Array of link objects.
 */

/**
 * InterestImagePopup component displays a modal popup with customizable content and styles.
 * 
 * @param {Object} props - Component props.
 * @param {PopupContent} props.content - Content to display inside the popup.
 * @param {boolean} props.isOpen - Controls the visibility of the popup.
 * @param {function} props.onClose - Function to call when the popup should close.
 * @param {'darker' | 'lighter'} props.variant - Determines the styling variant of the popup.
 */
export function InterestImagePopup({ content, isOpen, onClose, variant = 'darker' }) {
    // Early return if no content
    if (!content) {
      return null;
    }
  
    const popupStyles = {
      darker: {
        background: 'linear-gradient(145deg, rgba(40,40,40,0.6) 0%, rgba(0,0,0,0.8) 100%)',
        border: '1px solid rgba(255,255,255,0.2)',
        boxShadow: '0 0 20px rgba(255,255,255,0.1)',
        additionalImageClasses: 'bg-white/5',
        linkBackground: 'bg-gray-800',
        linkText: 'text-gray-300',
        linkHoverBg: 'hover:bg-gray-700',
        linkBorder: 'border-gray-600',
      },
      lighter: {
        background: 'linear-gradient(145deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%)',
        border: '1px solid rgba(255,255,255,0.2)',
        boxShadow: 'none',
        additionalImageClasses: 'bg-white/10',
        linkBackground: 'bg-white/20',
        linkText: 'text-white',
        linkHoverBg: 'hover:bg-white/30',
        linkBorder: 'none',
      },
    }
  
    const styles = popupStyles[variant] || popupStyles['darker']
  
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-8"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 backdrop-blur-md bg-black/70"
              onClick={onClose}
            />

            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="relative w-full max-w-4xl max-h-[85vh] overflow-y-auto rounded-2xl shadow-2xl font-sans"
              style={{
                background: styles.background,
                backdropFilter: 'blur(20px)',
                WebkitBackdropFilter: 'blur(20px)',
                border: styles.border,
                boxShadow: styles.boxShadow,
              }}
            >
              <button
                onClick={onClose}
                className="absolute right-4 top-4 z-10 rounded-full bg-white/10 p-2 backdrop-blur-md transition-all hover:bg-white/20"
                aria-label="Close Popup"
              >
                <X className="h-5 w-5 text-white" />
              </button>

              {/* Main Image Section with null checks */}
              {content.mainImage && (
                <div className="relative h-72 w-full overflow-hidden rounded-t-2xl">
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/80" />
                  <img
                    src={content.mainImage}
                    alt={content.title || 'Image'}
                    className="h-full w-full object-cover"
                  />
                  {content.title && (
                    <h1 className="absolute bottom-6 left-6 text-4xl font-bold tracking-tight text-white">
                      {content.title}
                    </h1>
                  )}
                </div>
              )}

              {/* Content Container */}
              <div className="space-y-8 p-6 text-white">
                {/* Description */}
                {content.description && (
                  <p className="text-xl font-light leading-relaxed">
                    {content.description}
                  </p>
                )}

                {/* Quote if available */}
                {content.quote && (
                  <div className="relative rounded-xl border border-white/20 bg-white/5 p-6 backdrop-blur-md">
                    <Quote className="absolute -left-3 -top-3 h-8 w-8 text-white/40" />
                    <blockquote className="pl-4">
                      <p className="text-lg italic text-white/90">{content.quote}</p>
                      {content.quoteAuthor && (
                        <footer className="mt-2 text-sm text-white/70">
                          — {content.quoteAuthor}
                        </footer>
                      )}
                    </blockquote>
                  </div>
                )}

                {/* Additional Images Grid */}
                {content.additionalImages?.length > 0 && (
                  <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                    {content.additionalImages.map((image, index) => (
                      <a
                        key={index}
                        href={image.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative aspect-video overflow-hidden rounded-lg border border-white/20 backdrop-blur-md bg-white/5 transition-transform duration-300 hover:scale-105"
                        aria-label={`View additional image ${index + 1}`}
                      >
                        <img
                          src={image.src}
                          alt={`Additional view ${index + 1}`}
                          className="h-full w-full object-cover"
                        />
                      </a>
                    ))}
                  </div>
                )}

                {/* Detailed Information */}
                {content.details && (
                  <div className="rounded-xl border border-white/20 bg-white/5 p-6 backdrop-blur-md">
                    <p className="text-lg leading-relaxed text-white/90">
                      {content.details}
                    </p>
                  </div>
                )}

                {/* Links */}
                {content.links?.length > 0 && (
                  <div className="flex flex-wrap gap-4">
                    {content.links.map((link, index) => (
                      <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center rounded-full ${styles.linkBackground} ${styles.linkText} px-6 py-3 text-sm font-medium backdrop-blur-md transition-all ${styles.linkHoverBg} ${styles.linkBorder}`}
                        aria-label={link.title}
                      >
                        {link.title}
                        <ExternalLink className="ml-2 h-4 w-4" />
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
}

export default InterestImagePopup